import {EventViews} from "../../enums/event-views";
import {CustomFieldDefinition} from "./custom-field-definition";
import {PaymentMethod} from "./payment-method";
import {PaymentMethodGiftCard} from "./payment-method-giftcard";
import {PicklistEntryDescriptor} from "./picklist-entry-descriptor";
import {SCPLOptions} from "./scpl-options";
import {ThemeProps} from "./theme-props";
import {UserProfile} from "./user-profile";
import { SubscriptionViews } from "../../enums/subscription-views";

export enum SuggestedDonationTypes {
	FIXED = "Fixed",
	PERCENTAGE = "Percentage",
}

export class PublicTicketAppConfig {

	///
	/// Required fields
	///
	
	public countdownTimerEnabled: boolean = false;
	public currencyCode: string = "USD";
	public currencySym: string = "$";
	public defaultEventView: EventViews;
	public locale: string = "en-US";
	public orderSources: PicklistEntryDescriptor[] = [];
	public salutations: PicklistEntryDescriptor[] = [];
	public supportedEventViews: EventViews[] = [];
	public timezone: string = "America/New_York";
	public userProfile: UserProfile; // the logged in user or the guest user if not logged in
	public canInjectCode: boolean = false;
	public errorContactInfo: string = '';
	public errorContactName: string = '';
	public currentDateTime: number;
	public ptsFilterCategories: string[] = [];
	public hideEventInstanceLearnMoreLinks: boolean = false;
	public subscriptionView: SubscriptionViews;
	public includeFeesInPrice: boolean = false;
	public isSalutationDisabled: boolean;
	public arePronounsEnabled: boolean;

	///
	/// Optionals
	///

	public customFields?: CustomFieldDefinition[];
	public defaultSubsChangeRequest?: string;
	public endpoint?: string; // todo specify as URL type
	public eventsContent?: string;
	public eventsUrl?: string | undefined = 'nolink';  // Default to nolink so we don't briefly flash the link when the property hasn't been fetched yet
	public forProfitOrg?: boolean;
	public gatewayType?: string;
	public isBluefin?: boolean;
	public isStripe?: boolean;
	public itemFeeLabel?: string;
	public membershipsUrl?: string | undefined = 'nolink';
	public membershipsContent?: string;
	public subscriptionCommentsLabel?: string;
	public orderFeeLabel?: string;
	public orderSourceLabel?: string;
	public paymentGatewayRequiresCcType?: boolean;
	public portalEnabled?: boolean;
	public salesTaxLabel?: string;
	public showSalesTax?: boolean;
	public stateCodes?: string[];
	public subscriptionsUrl?: string | undefined = 'nolink';
	public subscriptionsContent?: string;
	public donationLabel?: string;
	public suggestedDonationAmount?: number;
	public suggestedDonationType?: SuggestedDonationTypes | null;
	public donationCTAHeader?: string;
	public donationCTABody?: string;
	public todayFormatted?: string;
	public deliveryInformation?: string;
	public orderConfirmationCopy?: string;
	public portalLandingCopy?: string;
	public portalLoginPageMessage?: string;
	public portalSeatSelectionMessage?: string;
	public userPaymentMethods?: PaymentMethod[] = [];
	public userGiftCardPaymentMethods?: PaymentMethodGiftCard[] = [];
	public canUseSavedPaymentMethods?: boolean = false;
	public canSavePaymentMethods?: boolean = false;
	public disableDiscountPage?: boolean = false;
	public usesDiscountCodes?: boolean;
	public themeProps?: ThemeProps;
	// social sharing settings
	public fbAppId?: string;  //facebook app id
	public postToTwitter?: string;
	public socialSharingHeading?: string;
	public twitterHandle?: string;

	// PMGR-7330 Trustwave/Elavon compliance
	public dbaName?: string;
	public physicalAddress?: string;
	public privacyPolicyUrl?: string;
	public termsAndConditions?: string;
	public termsAndConditionsUrl?: string;
	
	public giftCardsEnabled?: boolean = false;
	
	public captchaSiteKey: string;
	
	public hideSubsRenewalTab: boolean;
	
	// PMGR9938 State and Country Picklist settings
	public isSCPLEnabled: boolean;
	public scplOptions: SCPLOptions[] = [];
	
	public onlineTicketText?: string;
	public mobileTicketOrgDetails?: string;
}
